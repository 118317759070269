import { nanoid } from 'nanoid';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CUSTOMER_JOURNEYS, FRAME_COLORS, LENS_COLORS, LENSES_PACKAGES, NEW_BASE_FRAME_NAMES } from '@constants';
// import { CartLineInput } from '@ts/shopify-storefront-api';
import { CartMutationArgs } from '@ts/cart';
import { DynamicBaseFrameVariant } from '@services/shopify/hooks/useBaseFrameVariant';
import { getVariantByOption } from './shopify';
import { handelize } from './strings';
import { RX_TYPE } from './constants/base-skus';

type GenerateBundleArgs = {
	demo: string;
	editingBundleKey?: string;
	frame: NEW_BASE_FRAME_NAMES;
	frameColor: FRAME_COLORS;
	journey: CUSTOMER_JOURNEYS;
	lensColor: LENS_COLORS;
	rxType: RX_TYPE;
	product: NormalizedProduct;
	readerRx?: string;
	submissionMethod: string;
	tops?: Record<string, NormalizedVariant>;
	BaseFrameVariant: DynamicBaseFrameVariant;
	lensPackage?: LENSES_PACKAGES;
	subscriptionVariant?: NormalizedVariant;
	sellingPlanId?: string;
	isV2Subscription?: boolean;
	pdMeasurement?: string;
};

/**
 * Generates the bundle for submitting to Cart
 *
 * Important notes:
 * - if journey is BLUE_LIGHT or SUN_LENS, a 'defaultLens' is added otherwise it is undefined for DEFAULT
 * - 'Readers' requires '_readerStrength' attribute
 * - 'Progressives' requires the Progressives lens product is added as an additional lens
 * - Submission Method is defaulted to 'REMIND' but can be overridden via RXSS
 *
 * @param ctx
 * @param _bundle_key
 * @returns bundle
 */
export const generateBundle = async ({
	frameColor,
	demo,
	editingBundleKey,
	frame,
	journey,
	lensColor,
	readerRx,
	rxType,
	product,
	submissionMethod,
	tops,
	BaseFrameVariant,
	lensPackage = LENSES_PACKAGES.BASIC,
	subscriptionVariant,
	sellingPlanId,
	isV2Subscription = false,
	pdMeasurement,
}: GenerateBundleArgs) => {
	const _bundle_key = editingBundleKey ?? nanoid();
	const _prescriptionType = getLineItemPropertyForRxType(rxType);

	const customAttributes = [
		{ key: CART_LINE_ATTRIBUTE_KEYS.BUNDLE_KEY, value: _bundle_key },
		{ key: CART_LINE_ATTRIBUTE_KEYS.CUSTOMER_JOURNEY, value: journey },
		{ key: CART_LINE_ATTRIBUTE_KEYS.CUSTOMER_TYPE, value: demo },
		{ key: CART_LINE_ATTRIBUTE_KEYS.FRAME, value: frame },
		{ key: CART_LINE_ATTRIBUTE_KEYS.PRESCRIPTION_TYPE, value: _prescriptionType },
		{ key: CART_LINE_ATTRIBUTE_KEYS.SUBMISSION_METHOD, value: submissionMethod },
	];

	if (pdMeasurement) customAttributes.push({ key: CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT, value: pdMeasurement });

	const bundle: CartMutationArgs['lineItems'] = [
		{
			variant: {
				...getVariantByOption(product, frameColor),
				id: BaseFrameVariant.data.variantBySelectedOptions.id,
			},
			quantity: 1,
			customAttributes,
		},
	];
	rxType === RX_TYPE.READERS &&
		bundle[0].customAttributes.push({ key: CART_LINE_ATTRIBUTE_KEYS.READER_STRENGTH, value: readerRx });

	if (journey === CUSTOMER_JOURNEYS.SUNGLASSES) {
		const bc = handelize(frameColor);
		const lc = handelize(lensColor);
		const addendum = `${bc}-${lc}_PDP_${frame.toLowerCase()}.png`;

		const sunLensAttributes = [
			{ key: CART_LINE_ATTRIBUTE_KEYS.LENS_COLOR, value: lensColor },
			{
				key: CART_LINE_ATTRIBUTE_KEYS.LENS_IMAGE_URL,
				value: `${process.env.NEXT_PUBLIC_SHOPIFY_CDN_PATH}/carousel-image-1-sun-${addendum}`,
			},
		];
		bundle[0].customAttributes.push(...sunLensAttributes);
	}
	if (!!tops) {
		Object.values(tops)?.forEach((top: NormalizedVariant) => {
			const isSubscriptionProduct = top?.handle === subscriptionVariant?.handle;
			if (isSubscriptionProduct) return;
			bundle.push({
				variant: top,
				quantity: 1,
				customAttributes: [
					{ key: CART_LINE_ATTRIBUTE_KEYS.BUNDLE_KEY, value: _bundle_key },
					{ key: CART_LINE_ATTRIBUTE_KEYS.FRAME, value: frame },
					{ key: CART_LINE_ATTRIBUTE_KEYS.COLLECTION_PATH, value: top.collection },
				],
			});
		});
	}

	if (subscriptionVariant) {
		bundle.push({
			variant: subscriptionVariant,
			quantity: 1,
			customAttributes: [
				{ key: CART_LINE_ATTRIBUTE_KEYS.BUNDLE_KEY, value: _bundle_key },
				{ key: CART_LINE_ATTRIBUTE_KEYS.FRAME, value: frame },
				{ key: CART_LINE_ATTRIBUTE_KEYS.COLLECTION_PATH, value: subscriptionVariant.collection || 'all-tops' },
				isV2Subscription && { key: '_custom_subscription_discount', value: '15.0' },
			].filter(Boolean),
			sellingPlanId: sellingPlanId,
		});
	}

	return bundle;
};

export const getLineItemPropertyForRxType = (rxType: RX_TYPE) => {
	switch (rxType) {
		case RX_TYPE.SINGLE_VISION:
			return 'single-vision';
		case RX_TYPE.READERS:
			return 'readers';
		case RX_TYPE.PROGRESSIVE:
			return 'progressives';
		case RX_TYPE.NON_RX:
			return 'non-prescription';
		default:
			return '';
	}
};
