import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { RX_TYPE } from '@utils/constants/base-skus';
import { NormalizedProduct, NormalizedVariant } from '@ts/index';
import { DynamicBaseFrameVariant } from '@services/shopify/hooks/useBaseFrameVariant';
import { useCart } from '@services/shopify';
import { useCartContext } from '@context';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import {
	CUSTOMER_JOURNEYS,
	FRAME_COLORS,
	generateBundle,
	LENS_COLORS,
	LENSES_PACKAGES,
	NEW_BASE_FRAME_NAMES,
	PRODUCT_TYPES,
	useLocalStorage,
} from '..';

export type BaseFrameCartManagerProps = {
	demo: string;
	editingBundleKey?: string;
	frame: NEW_BASE_FRAME_NAMES;
	frameColor: FRAME_COLORS;
	journey: CUSTOMER_JOURNEYS;
	lensColor: LENS_COLORS;
	rxType: RX_TYPE;
	product: NormalizedProduct;
	readerRx?: string;
	submissionMethod: string;
	tops?: Record<string, NormalizedVariant>;
	BaseFrameVariant: DynamicBaseFrameVariant;
	lensPackage?: LENSES_PACKAGES;
	subscriptionVariant?: NormalizedVariant;
	sellingPlanId?: string;
	isSubscriptionActivated?: boolean;
	isBuildFlow?: boolean;
	pdMeasurement?: string;
	redirectToCart?: boolean;
};

const useBaseFrameCartManager = ({
	isSubscriptionActivated = false,
	isBuildFlow = true,
	redirectToCart = true,
	...props
}: BaseFrameCartManagerProps) => {
	const { push, query, asPath, pathname } = useRouter();
	const { handleCartAdd, handleCartReplace } = useCartContext();
	const { data: cart } = useCart();
	const isBF2AllTops = useFeatureIsOn('bf-2-all-tops');
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [__, setBf2AllTops] = useLocalStorage('bf2AllTops', null);

	const savedBundle = cart?.bundles && query.bundleKey ? cart?.bundles[`${query.bundleKey}`] : null;
	const isEditMode = Boolean(query?.edit);

	const handleCartAction = useCallback(async () => {
		const cartBaseFrame = cart?.lines.find(
			line =>
				props.editingBundleKey === line.properties[CART_LINE_ATTRIBUTE_KEYS.BUNDLE_KEY] &&
				line.variant.type.includes(PRODUCT_TYPES.BASE_FRAME)
		);
		const submissionMethod =
			cartBaseFrame?.properties?.[CART_LINE_ATTRIBUTE_KEYS.SUBMISSION_METHOD] || props.submissionMethod;
		const pdMeasurement = cartBaseFrame?.properties?.[CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT] || props.pdMeasurement;
		const lineItems = await generateBundle({ ...props, submissionMethod, pdMeasurement, isV2Subscription });

		const bundleKey = lineItems[0].customAttributes.find(att => att.key === '_bundle_key').value;

		if (isEditMode) {
			const lineItemIds = [
				...(savedBundle?.base?.lenses?.map(lens => lens.id) || []),
				...(savedBundle?.base?.insurance ? [savedBundle.base.insurance.id] : []),
				...(savedBundle?.tops?.map(top => top.id) || []),
				...(savedBundle?.other?.map(line => line.id) || []),
				savedBundle.base.frame.id,
			];

			if (savedBundle?.base?.insurance) {
				lineItems.push({
					id: savedBundle.base.insurance.variant.product.id,
					variant: savedBundle.base.insurance.variant,
					quantity: 1,
					customAttributes: [{ key: '_bundle_key', value: bundleKey }],
				});
			}

			handleCartReplace(lineItemIds, lineItems);
		} else {
			handleCartAdd(lineItems, false);
		}

		const hasParams = asPath.includes('?');

		if (isBF2AllTops && !isSubscriptionActivated && !isEditMode && isBuildFlow) {
			setBf2AllTops(true);
			return push({ pathname: '/top-frames' });
		}
		if (!redirectToCart) {
			return push({ pathname, query: { ...query, bundleKey: bundleKey, edit: true, quickAdd: true } }, undefined, {
				shallow: true,
			});
		}
		return push({
			pathname: '/cart',
			query: {
				redirectTo: encodeURIComponent(`${hasParams ? asPath.split('?')[0] : asPath}?bundleKey=${bundleKey}`),
			},
		});
	}, [props, cart]);

	return handleCartAction;
};

export default useBaseFrameCartManager;
